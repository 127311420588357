import QRScanner from "../../components/QRScanner/qrscanner";
import styles from "./scanQRCode.module.scss";
import { useNavigate } from "react-router-dom";
import api from "../../api/api";
import apiUtil from "../../api/apiUtil";
import getToken from "../../api/getToken";
import { useTranslation } from "react-i18next";
import TokenHandler from "../../utils/TokenHandler";
import PropTypes from "prop-types";
import { useState } from "react";

const ScanQRCode = ({ source }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState();

  const onScanSuccess = (data) => {
    if (source === "/redeem-gift" && data) {
      const payload = {
        redeemId: data,
      };

      api
        .get("Partner/GetRedeemDetailAndCustomerInfo", payload, getToken())
        .then((result) =>
          apiUtil.parseResult(
            result,
            (response) => {
              if (response.data.code === 200 && response.data.data) {
                navigate("/redeem-gift/form", {
                  state: { redeemDetailCustomerInfo: response.data.data },
                });
              } else {
                setErrorMessage(response.data.message);
              }
            },
            (error) => {
              console.log(error);
            }
          )
        );
    } else if (source === "/point-add/qr-scan" && data) {
      const userData = JSON.parse(data);
      const payload = {
        userId: userData.member_id,
        brandId: userData.brand_id,
      };

      api
        .get("Partner/GetMemberInfoByPartner", payload, getToken())
        .then((result) =>
          apiUtil.parseResult(
            result,
            (response) => {
              if (
                response.data.statusCode === 200 &&
                response.data.value.data
              ) {
                navigate("/point-add/form", {
                  state: { memberInfo: response.data.value.data },
                });
              } else {
                setErrorMessage(response.data.value.message);
              }
            },
            (error) => {
              console.log(error);
            }
          )
        );
    } else {
      setErrorMessage("Wrong QR Code, Scan Again!!");
    }
  };

  return (
    <div className={styles.main}>
      <TokenHandler />
      <div className={styles.camera}>
        <QRScanner onScanSuccess={onScanSuccess} />
        {errorMessage ? (
          <div className={styles.qrText}>{errorMessage}</div>
        ) : (
          <div className={styles.qrText}>{t("showQRCode")}</div>
        )}
      </div>
    </div>
  );
};

ScanQRCode.propTypes = {
  source: PropTypes.string,
};

export default ScanQRCode;
