const apiUtil = {
  getBaseUrl: () => {
    return window.location.protocol + "//" + window.location.host;
  },

  parseResult(result = null, success = null, error = null) {
    if (result.status === 500) {
      if (error) {
        if (result && result.errorMessage && result.errorMessage.error !== "") {
          // error('Add something', 'warning', null);
        } else {
          // error('Unknown error occurred. Please try again', 'warning', null);
        }
      }
      return;
    }

    if (result.errorMessage === null || result.errorMessage === "") {
      if (result && result.data && result.data && result.data.error) {
        if (error) {
        }
      } else if (result && result.data) {
        let data = result.data;
        if (success) {
          success(data, "success", null);
        }
      } else {
        if (error) {
          error("An error occurred, please try again!", "warning", null);
        }
      }
    }
  },

  customFilter(array, param, value) {
    let items =
      array && array.length > 0
        ? array.filter((x) => {
            if (value !== null) {
              return (
                x[param].toString().toLowerCase().trim() ===
                value.toString().toLowerCase().trim()
              );
            }
          })
        : [];
    return items && items.length > 0 ? items[0] : null;
  },

  filterByAll(array, value) {
    return array.filter((o) =>
      Object.keys(o).some((k) =>
        o[k].toString().toLowerCase().includes(value.toString().toLowerCase())
      )
    );
  },
  filterByParam(array, param, value) {
    return array.filter((o) =>
      Object.keys(o).some((k) =>
        o[param]
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase())
      )
    );
  },
  filterByParams(array, params, value) {
    return array.filter((o) =>
      Object.keys(o).some((k) => {
        for (let i = 0; i < params.length; i++) {
          let param = params[i];

          if (o[param]) {
            return o[param]
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase());
          }
        }
      })
    );
  },

  isNumeric: (x) => {
    return parseFloat(String(x)) === x;
  },

  emailValidation(email) {
    var emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailReg.test(email);
  },
  passwordValidation(str, regex = null) {
    if (regex === null) {
      // Minimum eight characters, at least one letter, one number and one special character
      return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}/g.test(
        str
      );
    } else {
      return regex.test(str);
    }
  },
  passwordValidationSimple(str) {
    // The password must contain at least six letters or numbers
    return /[A-Za-z\d@$!%*#?&]{6,}/g.test(str);
  },

  getPaginationCount(totalRows, rowsPerPageCount) {
    let mod = totalRows % rowsPerPageCount;
    let pages = totalRows / rowsPerPageCount;
    return mod > 0 ? parseInt(pages) + 1 : pages;
  },

  getMaxPageSize: () => {
    return 99999;
  },
  getDefaultPageSize: () => {
    return 10;
  },
  getDefaultDateFormat: () => {
    return "YYYY-MM-DD";
  },
  getDefaultTimeFormat: () => {
    return "HH:mm";
  },
  getDefaultDateTimeFormat: () => {
    return "YYYY-MM-DD HH:mm";
  },

  getImportDateFormat: () => {
    return "DD/MM/YYYY";
  },
  getImportTimeFormat: () => {
    return "HH:mm A";
  },
  getImportDateTimeFormat: () => {
    return "DD/MM/YYYY HH:mm A";
  },
  getDateShortMonthFormat: (date) => {
    return new Date(date)
      .toLocaleDateString("en-GB", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      })
      .replaceAll("/", " ");
  },

  getDateShortMonthByUSFormat: (date) => {
    return new Date(date)
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      })
      .replaceAll("/", " ");
  },

  statusToClassName: (status = "") => {
    return status.toString().toLowerCase().trim().replace(" ", "-");
  },

  fileToBase64(file) {
    return new Promise((resolve) => {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => resolve("");
      } else {
        resolve("");
      }
    });
  },
  blobToBase64: async (blob) => {
    return new Promise((resolve) => {
      if (blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => resolve("");
      } else {
        resolve("");
      }
    });
  },
};

export default apiUtil;
