import styles from "./pointUsed.module.scss";
import TokenHandler from "../../utils/TokenHandler";
import { CloseButton } from "../../assets/svgCollection";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import PropTypes from "prop-types";

const PointUsed = ({ setStatus, setShowModal, setQrData }) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState("");

  const handleConfirmClick = (e) => {
    e.preventDefault();
    const userId =
      sessionStorage.getItem("brandId") || localStorage.getItem("brandId");
    setQrData((prevState) => ({ ...prevState, userId, amount }));
    setStatus(false);
    setShowModal(true);
  };

  return (
    <div className={styles.main}>
      <TokenHandler />
      <div className={styles.container}>
        {/* banner */}
        <div className={styles.banner}>
          <div className={styles.bannerText}>Point Used</div>
          <button className={styles.close} onClick={() => setStatus(false)}>
            <CloseButton />
          </button>
        </div>

        {/* form */}
        <form className={styles.form} onSubmit={handleConfirmClick}>
          <div className={styles.points}>
            <label htmlFor="amount">Use Points</label>
            <input
              type="text"
              id="amount"
              name="amount"
              placeholder="Enter Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </div>

          <div className={styles.buttons}>
            <button className={styles.cancel} onClick={() => setStatus(false)}>
              {t("cancel")}
            </button>

            <button className={styles.confirm} type="submit">
              {t("confirm")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

PointUsed.propTypes = {
  setStatus: PropTypes.func,
  setShowModal: PropTypes.func,
  setQrData: PropTypes.func,
};

export default PointUsed;
