import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const TokenHandler = () => {
  useEffect(() => {
    const token =
      sessionStorage.getItem("token") || localStorage.getItem("token");

    if (token) {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decoded.exp < currentTime) {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("brandId");
          sessionStorage.removeItem("userId");

          localStorage.removeItem("token");
          localStorage.removeItem("brandId");
          localStorage.removeItem("userId");

          alert("token expires");
          window.location.href = "/login";
        }
      } catch (error) {
        console.error("Token decoding failed:", error);
      }
    } else {
      console.log("token not found");
      window.location.href = "/login";
    }
  }, []);
};

export default TokenHandler;
