import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  // English translations
  en: {
    translation: {
      redeemGift: "Redeem Gift",
      points: "Point Add",
      setting: "Setting",
      language: "Language",
      myanmar: "Myanmar",
      english: "English",
      cancel: "Cancel",
      save: "Save",
      confirm: "Confirm",
      reject: "Reject",
      approve: "Approve",
      name: "Name",
      totalPoints: "Total Points",
      addPoints: "Add Points",
      usePoints: "Use Points",
      phoneNumber: "Phone Number",
      enterPhoneNumber: "Enter a Customer Name or Phone Number",
      qrScan: "QR Scan",
      showQRCode: "Show QR Code to Scanner",
      scanQRCode: "Scan QR Code",
      search: "Search",
      phoneSearchEmpty: "Please enter a phone number to search with!",
      phoneSearch:
        "To find Customer information, simply enter a phone number in the search bar!",
      customerInformation: "Customer Information",
      goToHome: "Go to Home",
      pointUsed: "Point Used",
      redeemGiftRejected: "Gift Redeem Rejected!",
      redeemGiftRejectedText: "Your gift has been rejected!",
      redeemGiftSuccess: "Gift Redeem Successful!",
      redeemGiftSuccessText:
        "Congratulations! Your gift has been successfully redeemed. Enjoy your reward!",
      pointAddSuccess: "Point Addition Successful!",
      pointAddSuccessText: "Congratulations!",
      pointAddFail: "Point Addition Failed!",
      pointAddFailText: "Your point addition has been a failure!",
    },
  },
  // Burmese translations
  mm: {
    translation: {
      redeemGift: "လက်ဆောင်ထုတ်ရန်",
      points: "ပွိုင့်တိုးရန်",
      setting: "ချိန်ညှိရန်",
      language: "ဘာသာစကား",
      myanmar: "မြန်မာ",
      english: "အင်္ဂလိပ်",
      cancel: "မလုပ်ဘူး",
      save: "လုပ်မယ်",
      confirm: "အတည်ပြု",
      reject: "ပယ်ချ",
      approve: "အတည်ပြု",
      name: "နာမည်",
      totalPoints: "ပွိုင့်စုစုပေါင်း",
      addPoints: "ထပ်တိုးပွိုင့်",
      usePoints: "အသုံးပြုရမည့်ပွိုင့်",
      phoneNumber: "ဖုန်းနံပါတ်",
      enterPhoneNumber: "ဖုန်းနံပါတ် ရိုက်ထည့်ပါ",
      qrScan: "ကျူအာ စကန်",
      showQRCode: "သင်၏ ကျူအာကုဒ်ကို ကင်မရာကိုပြပေးပါ",
      scanQRCode: "ကျူအာကုဒ်ကိုစကန်ဖတ်ပါ ",
      search: "ရှာရန်",
      phoneSearchEmpty: "၀ယ်သူ၏ အချက်အလက်ရှာရန် ဖုန်းနံပါတ် ရိုက်ထည့်ပါ",
      phoneSearch:
        "ဖုန်းနံပါတ် ရိုက်ထည့်ရုံဖြင့် ၀ယ်ယူသူ၏ အချက်အလက်ကိုရှာဖွေနိူင်ပါသည်",
      customerInformation: "၀ယ်ယူသူ၏အချက်အလက်",
      goToHome: "ပင်မစာမျက်နှာ",
      pointUsed: "ပွိုင့်သုံးရန်",
      redeemGiftRejected: "လက်ဆောင်ထုတ်ယူခြင်းအားပယ်ဖျက်လိုက်ပါပီ",
      redeemGiftRejectedText: "သင့်ရဲ့လက်ဆောင်ကိုပယ်ဖျက်လိုက်ပါပီ",
      redeemGiftSuccess: "လက်ဆောင်ထုတ်ယူခြင်းအောင်မြင်ပါတယ်",
      redeemGiftSuccessText:
        "ဂုဏ်ယူပါတယ် သင့်ရဲ့လက်ဆောင်ကိုအောင်မြင်စွာထုတ်ယူပီးပါပီ",
      pointAddSuccess: "ပွိုင့်ထပ်တိုးခြင်းအောင်မြင်ပါတယ်",
      pointAddSuccessText: "ဂုဏ်ယူပါတယ်",
      pointAddFail: "ပွိုင့်ထပ်တိုးခြင်းမအောင်မြင်ပါ",
      pointAddFailText: "သင့်ရဲ့ပွိုင့်ထပ်တိုးခြင်းလုပ်ငန်းစဉ်မှာမအောင်မြင်ပါ",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "mm",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
