import { useState } from "react";
import styles from "./phoneNumber.module.scss";
import { SearchLens } from "../../assets/svgCollection";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/api";
import apiUtil from "../../api/apiUtil";
import getToken from "../../api/getToken";
import { Form, Formik, Field } from "formik";
import { useTranslation } from "react-i18next";
import TokenHandler from "../../utils/TokenHandler";

const PhoneNumber = () => {
  const brandId =
    sessionStorage.getItem("brandId") || localStorage.getItem("brandId");
  const [showResult, setShowResult] = useState(null);
  const [searchError, setSearchError] = useState(null);
  const [memberInfo, setMemberInfo] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSearchClick = async (values) => {
    if (!values.Phone) {
      setSearchError(true);
      console.log("no input");
      return;
    }

    const payload = {
      Phone: values.Phone,
      brandId: brandId,
    };

    api
      .get("Partner/GetMemberInfoByPhone", payload, getToken())
      .then((result) =>
        apiUtil.parseResult(
          result,
          (response) => {
            if (response.data.statusCode === 200 && response.data.value.data) {
              setMemberInfo(response.data.value.data);
              setShowResult(true);
              setSearchError(false);
            } else if (
              response.data.statusCode === 200 &&
              !response.data.value.data
            ) {
              setMemberInfo(response.data.value.message);
              setShowResult(false);
              setSearchError(false);
            } else if (response.data.code === 401) {
              alert(response.data.message);
              setShowResult(false);
              setSearchError(false);
            }
          },
          (error) => {
            console.log(error);
          }
        )
      );
  };

  const handleResultClick = () => {
    if (memberInfo) {
      navigate("/point-add/form", { state: { memberInfo: memberInfo } });
      setMemberInfo(null);
    }
  };

  let content;
  if (searchError) {
    content = <div className={styles.error}>{t("phoneSearchEmpty")}</div>;
  } else if (!showResult && memberInfo) {
    content = <div className={styles.error}>{memberInfo}</div>;
  } else if (showResult && memberInfo) {
    content = (
      <button
        className={styles.resultBox}
        onClick={handleResultClick}
      >
        <Link to="/point-add/form" className={styles.result}>
          <div className={styles.name}>{memberInfo.name}</div>
          <div className={styles.number}>{memberInfo.phoneNo}</div>
        </Link>
      </button>
    );
  } else {
    content = <div className={styles.text}>{t("phoneSearch")}</div>;
  }

  return (
    <div className={styles.container}>
      <TokenHandler />
      <div className={styles.main}>
        <Formik
          initialValues={{
            Phone: "",
            brandId: "",
          }}
          onSubmit={handleSearchClick}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
          }) => (
            <Form className={styles.inputForm}>
              <div className={styles.searchBox}>
                <div className={styles.search}>
                  <SearchLens />
                  <Field
                    type="text"
                    id="Phone"
                    name="Phone"
                    placeholder={t("enterPhoneNumber")}
                  />
                </div>
                <button className={styles.box} type="submit">
                  {t("search")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        {content}
      </div>
    </div>
  );
};

export default PhoneNumber;
