import Navbar from "../components/Navbar/navbar";
import styles from "./layout.module.scss";
import PropTypes from "prop-types";
import { useState } from "react";

const Layout = ({ children, pathname }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <nav
      className={styles.layout}
      onClick={() => (isDropdownOpen === true ? setIsDropdownOpen(false) : "")}
    >
      <Navbar
        pathname={pathname}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
      />
      <div>{children}</div>
    </nav>
  );
};

Layout.propTypes = {
  children: PropTypes.object,
  pathname: PropTypes.string,
};

export default Layout;
