import styles from "./login.module.scss";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import api from "../../api/api";
import apiUtil from "../../api/apiUtil";
import { useState } from "react";

const Login = () => {
  const [isRemember, setIsRemember] = useState(false);

  const handleRememberMe = (e) => {
    setIsRemember(e.target.checked);
  };

  const handleSubmit = async (values, actions) => {
    const payload = {
      userName: values.username,
      password: values.password,
      userType: values.userType,
      brand_Id: process.env.REACT_APP_BRAND_ID,
    };
    console.log(payload);

    api.post("Authentication/Authenticate", payload).then((result) =>
      apiUtil.parseResult(
        result,
        (response) => {
          console.log(response);
          if (response.data.code === 200) {
            const storage = isRemember ? localStorage : sessionStorage;
            storage.setItem("token", response.data.data.token);
            storage.setItem("brandId", response.data.data.brandId);
            storage.setItem("userId", response.data.data.userId);
            window.location.href = "/";
          } else {
            alert(response.data.message);
          }
        },
        (error) => {
          console.log(error);
        }
      )
    );

    actions.setSubmitting(false);
  };

  const validationSchema = yup.object().shape({
    username: yup.string().required("Please enter the username."),
    password: yup
      .string()
      .required("Please enter the password.")
      .min(8, "Password must be at least 8 characters."),
  });

  return (
    <div className={styles.loginPage}>
      <div className={styles.loginForm}>
        <div className={styles.banner}>
          <div className={styles.heading}>Staff Portal</div>
          <div className={styles.text}>
            Please enter your credentials to access your account:
          </div>
        </div>
        <Formik
          initialValues={{ username: "", password: "", userType: 3 }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className={styles.inputForm}>
              <div className={styles.inputs}>
                <div className={styles.inputBox}>
                  <label htmlFor="username" className={styles.inputLabel}>
                    User Name
                  </label>
                  <Field
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Enter User Name"
                    className={styles.inputField}
                  />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className={styles.validationText}
                  />
                </div>
                <div className={styles.inputBox}>
                  <label htmlFor="password" className={styles.inputLabel}>
                    Password
                  </label>
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Enter Password"
                    className={styles.inputField}
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className={styles.validationText}
                  />
                </div>
                <div className={styles.rememberForget}>
                  <div className={styles.remember}>
                    <input
                      type="checkbox"
                      id="rememberMe"
                      checked={isRemember}
                      onChange={handleRememberMe}
                    />
                    <label htmlFor="rememberMe">Remember Me</label>
                  </div>
                  <div className={styles.forget}>
                    <p>Forget Password?</p>
                  </div>
                </div>
              </div>

              <button
                className={styles.button}
                type="submit"
                disabled={isSubmitting}
              >
                <p className={styles.buttonText}>Log In</p>
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
