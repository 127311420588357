import styles from "./pointAddModal.module.scss";
import { SuccessIcon, FailedIcon } from "../../assets/svgCollection";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const PointAddModal = ({ status, modalData }) => {
  const { t } = useTranslation();

  let content;
  if (status === "approve") {
    // approve modal
    content = (
      <div className={styles.content}>
        <div className={styles.icon}>
          <SuccessIcon />
        </div>
        <div className={styles.textSupport}>
          <div className={styles.text}>{t("pointAddSuccess")}</div>
          <div className={styles.support}>
            {t("pointAddSuccessText")} {modalData.points} points have been
            successfully added to {modalData.name}.
          </div>
        </div>
        <Link to="/" className={styles.button}>
          {t("goToHome")}
        </Link>
      </div>
    );
  } else if (status === "reject" || status === "error") {
    // reject or error modal
    content = (
      <div className={styles.content}>
        <div className={styles.icon}>
          <FailedIcon />
        </div>
        <div className={styles.textSupport}>
          <div className={styles.text}>{t("pointAddFail")}</div>
          <div className={styles.support}>{t("pointAddFailText")}</div>
        </div>
        <Link to="/" className={styles.button}>
          {t("goToHome")}
        </Link>
      </div>
    );
  }

  return (
    <div className={styles.pointAddModal}>
      <div className={styles.main}>{content}</div>
    </div>
  );
};

PointAddModal.propTypes = {
  status: PropTypes.string.isRequired,
  modalData: PropTypes.shape({
    points: PropTypes.number,
    name: PropTypes.string,
  }),
};

export default PointAddModal;
