import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./pages/Home/home";
import Login from "./pages/Login/login";
import Layout from "./layouts/layout";
import PointAdd from "./pages/PointAdd/pointAdd";
import Setting from "./pages/Setting/setting";
import ScanQRCode from "./pages/ScanQRCode/scanQRCode";
import PhoneNumber from "./pages/PhoneNumber/phoneNumber";
import RedeemGiftForm from "./pages/RedeemGiftForm/redeemGiftForm";
import PointAddForm from "./pages/PointAddForm/pointAddForm";
import { useTranslation } from "react-i18next";

const Router = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = () => {
      const token =
        sessionStorage.getItem("token") || localStorage.getItem("token");
      if (!token) {
        navigate("/login");
      }
    };

    checkAuthentication();
  }, [navigate]);

  return (
    <Routes>
      {/* login page */}
      <Route path="/login" element={<Login />} />

      {/* home page */}
      <Route
        path="/"
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />

      {/* redeem gift page */}
      <Route
        path="/redeem-gift"
        element={
          <Layout pathname="Redeem Gift">
            <ScanQRCode source="/redeem-gift" />
          </Layout>
        }
      />

      {/* redeem gift/form page */}
      <Route
        path="/redeem-gift/form"
        element={
          <Layout pathname="Redeem Gift">
            <RedeemGiftForm />
          </Layout>
        }
      />

      {/* point add page */}
      <Route
        path="/point-add"
        element={
          <Layout pathname={t("points")}>
            <PointAdd />
          </Layout>
        }
      />

      {/* point add/phone-number page */}
      <Route
        path="/point-add/phone-number"
        element={
          <Layout pathname={t("points")}>
            <PhoneNumber />
          </Layout>
        }
      />

      {/* point add/qr-scan page */}
      <Route
        path="/point-add/qr-scan"
        element={
          <Layout pathname={t("points")}>
            <ScanQRCode source="/point-add/qr-scan" />
          </Layout>
        }
      />

      {/* point add/form page */}
      <Route
        path="/point-add/form"
        element={
          <Layout pathname={t("points")}>
            <PointAddForm />
          </Layout>
        }
      />

      {/* setting page */}
      <Route
        path="/setting"
        element={
          <Layout pathname={t("setting")}>
            <Setting />
          </Layout>
        }
      />
    </Routes>
  );
};

export default Router;
