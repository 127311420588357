import styles from "./home.module.scss";
import { Link } from "react-router-dom";
import { Points, RedeemGift, Setting } from "../../assets/svgCollection";
import { useTranslation } from "react-i18next";
import TokenHandler from "../../utils/TokenHandler";

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>
        <TokenHandler />
        {/* qr tab */}
        <Link className={styles.tab} to="/redeem-gift">
          <div className={styles.img}>
            <RedeemGift />
          </div>
          <div className={styles.text}>{t("redeemGift")}</div>
        </Link>

        {/* point tab */}
        <Link className={styles.tab} to="/point-add">
          <div className={styles.img}>
            <Points />
          </div>
          <div className={styles.text}>{t("points")}</div>
        </Link>

        {/* setting tab */}
        <Link className={styles.tab} to="/setting">
          <div className={styles.img}>
            <Setting />
          </div>
          <div className={styles.text}>{t("setting")}</div>
        </Link>
      </div>
    </div>
  );
};
export default Home;
