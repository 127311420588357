const token = sessionStorage.getItem("token") || localStorage.getItem("token");

const getToken = () => {
  if (!token) {
    alert("Token is expired or missing. Please login again!!");
    window.location.href = "/login";
    return null;
  }
  return token;
};

export default getToken;
