import styles from "./pointUsedModal.module.scss";
import { FailedIcon } from "../../assets/svgCollection";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import QRCode from "react-qr-code";

const PointUsedModal = ({ qrData }) => {
  console.log(qrData);
  const { t } = useTranslation();
  const hasData = qrData;

  let content;
  if (hasData) {
    content = (
      <div className={styles.content}>
        <div className={styles.qrBack}>
          <QRCode
            value={qrData}
            size={256}
            fgColor="#384BCA"
            style={{
              padding: "20px",
            }}
          />
        </div>
        <div className={styles.textSupport}>
          <div className={styles.text}>{t("scanQRCode")}</div>
        </div>
        <Link to="/" className={styles.button}>
          {t("goToHome")}
        </Link>
      </div>
    );
  } else {
    content = (
      <div className={styles.content}>
        <div className={styles.icon}>
          <FailedIcon />
        </div>
        <div className={styles.textSupport}>
          <div className={styles.text}>{t("pointAddFail")}</div>
        </div>
        <Link to="/" className={styles.button}>
          {t("goToHome")}
        </Link>
      </div>
    );
  }

  return (
    <div className={styles.pointAddModal}>
      <div className={styles.main}>{content}</div>
    </div>
  );
};

PointUsedModal.propTypes = {
  qrData: PropTypes.object,
};

export default PointUsedModal;
