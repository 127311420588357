import styles from "./redeemGiftForm.module.scss";
import RedeemGiftModal from "../../components/RedeemGiftModal/redeemGiftModal";
import { useLocation } from "react-router-dom";
import api from "../../api/api";
import apiUtil from "../../api/apiUtil";
import getToken from "../../api/getToken";
import { Form, Formik } from "formik";
import { useRef,useState } from "react";
import { useTranslation } from "react-i18next";
import TokenHandler from "../../utils/TokenHandler";

const RedeemGiftForm = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(null);
  const location = useLocation();
  const formRef = useRef();

  const {
    redeemDetailCustomerInfo = {
      id: "",
      image: "",
      itemName: "",
      description: "",
      customerName: "",
      phoneNo: "",
      point: "",
      pointRequired: "",
      usedPoint: "",
    },
  } = location.state || {};
  console.log(redeemDetailCustomerInfo);

  const handleSubmit = async (values, actions) => {
    const payload = {
      customerRedeemId: redeemDetailCustomerInfo.id,
      status: values.status,
    };
    console.log(payload);

    api.post("Partner/ApproveRedeem", payload, getToken()).then((result) =>
      apiUtil.parseResult(
        result,
        (response) => {
          console.log(response);
          if (response.data.code === 200) {
            console.log("submit success");
            if (values.status === 1) {
              setStatus("approve");
            } else if (values.status === 2) {
              setStatus("reject");
            }
          } else {
            setStatus("error");
          }
        },
        (error) => {
          console.log(error);
        }
      )
    );
  };

  const handleAction = (status) => {
    if (formRef.current) {
      formRef.current.setFieldValue("status", status, false);
    }
  };

  return (
    <div className={styles.main}>
      <TokenHandler/>
      <div className={styles.forms}>
        {/* redeem gift tab */}
        <div className={styles.redeemGift}>
          <div className={styles.banner}>{t("redeemGift")}</div>
          <div className={styles.redeemGiftDetail}>
            <div className={styles.image}>
              <img src={redeemDetailCustomerInfo.image} alt="" />
            </div>
            <div className={styles.points}>
              <div className={styles.banner}>
                {redeemDetailCustomerInfo.itemName}
              </div>
              <div className={styles.point}>
                <div>{t("points")}</div>
                <div>{redeemDetailCustomerInfo.pointRequired} Pts</div>
              </div>
            </div>
            <div className={styles.description}>
              {redeemDetailCustomerInfo.description}
            </div>
          </div>
        </div>

        {/* customer info tab */}
        <div className={styles.customerInfo}>
          <div className={styles.banner}>{t("customerInformation")}</div>
          <Formik
            innerRef={formRef}
            initialValues={{
              customerRedeemId: "",
              status: "",
            }}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
            }) => (
              <Form className={styles.details}>
                <div className={styles.detail}>
                  <label htmlFor="">{t("name")}</label>
                  <input
                    type="text"
                    value={redeemDetailCustomerInfo.customerName}
                    readOnly
                  />
                </div>

                <div className={styles.detail}>
                  <label htmlFor="">{t("phoneNumber")}</label>
                  <input
                    type="text"
                    value={redeemDetailCustomerInfo.phoneNo}
                    readOnly
                  />
                </div>

                <div className={styles.detail}>
                  <label htmlFor="">{t("totalPoints")}</label>
                  <input
                    type="text"
                    value={redeemDetailCustomerInfo.point}
                    readOnly
                  />
                </div>

                <div className={styles.detail}>
                  <label htmlFor="">{t("usePoints")}</label>
                  <input
                    type="text"
                    value={redeemDetailCustomerInfo.usedPoint}
                    readOnly
                  />
                </div>

                <div className={styles.customerInfoButtons}>
                  <button
                    className={styles.reject}
                    onClick={() => handleAction(2)}
                    type="submit"
                  >
                    {t("reject")}
                  </button>
                  <button
                    className={styles.accept}
                    onClick={() => handleAction(1)}
                    type="submit"
                  >
                    {t("approve")}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      {/* success, failure modal box */}
      {status && (
        <div className={styles.modal}>
          <RedeemGiftModal status={status} />
        </div>
      )}
    </div>
  );
};
export default RedeemGiftForm;
