import axios from "axios";

const API_URL = process.env.REACT_APP_URL;
console.log(API_URL);

const PATH = API_URL;
const api = {
  get: async (url, data, token) => {
    let errorMessage = "";
    let status = 0;

    const URL = PATH + url;

    const getResult = await axios
      .get(URL, {
        params: data,
        // data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .catch((error) => {
        try {
          status = error.response.status;

          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
          }
        } catch (err) {
          errorMessage = "Service Unavailable!";
        }
      });

    return { data: getResult, errorMessage: errorMessage, status: status };
  },

  post: async (api, data, token) => {
    let errorMessage = "";
    let status = 0;

    const URL = PATH + api;

    const getResult = await axios
      .post(URL, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .catch((error) => {
        try {
          status = error.response.status;

          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
          }
        } catch (err) {
          errorMessage = "Service Unavailable!";
        }
      });
    return { data: getResult, errorMessage: errorMessage, status: status };
  },

  details: async (api, id, token) => {
    console.log(id);
    let errorMessage = "";
    let status = 0;

    const URL = PATH + api + "/" + id;

    const getResult = await axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .catch((error) => {
        try {
          status = error.response.status;

          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
          }
        } catch (err) {
          errorMessage = "Service Unavailable!";
        }
      });

    return { data: getResult, errorMessage: errorMessage, status: status };
  },
};

export default api;
