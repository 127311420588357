import styles from "./setting.module.scss";
import { useState } from "react";
import LanguageSetting from "../../components/LanguageSetting/languageSetting";
import { useTranslation } from "react-i18next";
import TokenHandler from "../../utils/TokenHandler";

const Setting = () => {
  const { t } = useTranslation();

  const items = [t("language")];
  const [selectedItem, setSelectedItem] = useState("");

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const renderContent = () => {
    switch (selectedItem) {
      case t("language"):
        return <LanguageSetting />;
      case "item2":
        return <div>Item 2 content</div>;
      case "item3":
        return <div>Item 3 content</div>;
      default:
        return (
          <div>
            <LanguageSetting />
          </div>
        );
    }
  };

  return (
    <div className={styles.main}>
      <TokenHandler />
      <div className={styles.sidebar}>
        <div className={styles.items}>
          {items.map((item) => (
            <button
              key={item}
              className={styles.item}
              onClick={() => handleItemClick(item)}
            >
              <div className={styles.itemHeading}>{item}</div>
            </button>
          ))}
        </div>
      </div>

      <div className={styles.contents}>
        <div className={styles.content}>{renderContent()}</div>
      </div>
    </div>
  );
};

export default Setting;
