import profilePic from "../../assets/profile.png";
import styles from "./navbar.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BackArrow } from "../../assets/svgCollection";
import api from "../../api/api";
import apiUtil from "../../api/apiUtil";
import getToken from "../../api/getToken";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const Navbar = ({ pathname, isDropdownOpen, setIsDropdownOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isHome = location.pathname === "/";
  const [profileData, setProfileData] = useState({});

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleProfileData = async () => {
    const payload = {
      userId:
        sessionStorage.getItem("userId") || localStorage.getItem("userId"),
      brandId:
        sessionStorage.getItem("brandId") || localStorage.getItem("brandId"),
    };

    api
      .get("Partner/GetAdminInfoByPartner", payload, getToken())
      .then((result) =>
        apiUtil.parseResult(
          result,
          (response) => {
            if (response.data.code === 200 && response.data.data) {
              setProfileData(response.data.data);
            } else {
              console.log("error fetching admin data");
            }
          },
          (error) => {
            console.log(error);
          }
        )
      );
  };

  const handleLogout = async () => {
    api
      .post(
        `Authentication/Logout?userId=${
          sessionStorage.getItem("userId") || localStorage.getItem("userId")
        }`
      )
      .then((result) =>
        apiUtil.parseResult(
          result,
          (response) => {
            if (response) {
              localStorage.removeItem("token");
              localStorage.removeItem("brandId");
              localStorage.removeItem("userId");

              sessionStorage.removeItem("token");
              sessionStorage.removeItem("brandId");
              sessionStorage.removeItem("userId");

              window.location.href = "/login";
            } else {
              alert("error logging out from the server");
            }
          },
          (error) => {
            console.log(error);
          }
        )
      );
  };

  useEffect(() => {
    handleProfileData();
  }, []);

  return (
    <div className={styles.navbar}>
      {isHome ? (
        <>
          <Link className={styles.banner} to="/">
            Staff Portal
          </Link>
          <button className={styles.profile} onClick={toggleDropdown}>
            <img src={profilePic} alt="" />
            {isDropdownOpen && (
              <div className={styles.dropdownContent}>
                <div>{profileData.name ? profileData.name : ""}</div>
                <div>{profileData.email ? profileData.email : ""}</div>
                <div>{profileData.phone ? profileData.phone : ""}</div>
                <button onClick={handleLogout}>Log Out</button>
              </div>
            )}
          </button>
        </>
      ) : (
        <div className={styles.banner}>
          <button
            onClick={() => {
              setIsDropdownOpen(false);
              navigate("/");
            }}
            className={styles.svg}
          >
            <BackArrow />
          </button>
          <div className={styles.headerPanelText}>{pathname}</div>
        </div>
      )}
    </div>
  );
};

Navbar.propTypes = {
  pathname: PropTypes.string,
  isDropdownOpen: PropTypes.bool,
  setIsDropdownOpen:PropTypes.func
};

export default Navbar;
