import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Routes";
import "./App.scss";
import { I18nextProvider } from "react-i18next";
import i18n from "./components/i18n";

const App = () => {
  const rootFontSize = getComputedStyle(document.documentElement).fontSize;
  console.log(rootFontSize);

  useEffect(() => {
    const selectedLanguage = localStorage.getItem("language");
    i18n.changeLanguage(selectedLanguage);
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </I18nextProvider>
  );
};

export default App;
