import styles from "./pointAddForm.module.scss";
import profile from "../../assets/profile.png";
import { CloseButton } from "../../assets/svgCollection";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Formik, Field } from "formik";
import api from "../../api/api";
import apiUtil from "../../api/apiUtil";
import getToken from "../../api/getToken";
import { useState } from "react";
import PointAddModal from "../../components/PointAddModal/pointAddModal";
import { useTranslation } from "react-i18next";
import TokenHandler from "../../utils/TokenHandler";

const PointAddForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState(null);
  const { t } = useTranslation();

  const {
    memberInfo = { name: "", phoneNo: "", point: "", id: "", brandId: "" },
  } = location.state || {};

  const [modalData, setModalData] = useState({
    name: memberInfo.name,
    points: "",
  });

  const [pointType, setPointType] = useState("");

  const handleSearchClick = async (values) => {
    const payload = {
      brandId: memberInfo.brandId,
      memberId: memberInfo.id,
      usedPoint: pointType === "addPoints" ? 0 : values.point,
      amount: pointType === "usePoints" ? 0 : values.amount,
    };
    console.log(payload);

    api.post("Partner/AddPoint", payload, getToken()).then((result) =>
      apiUtil.parseResult(
        result,
        (response) => {
          if (response.data.statusCode === 200) {
            setModalData({
              ...modalData,
              points: payload.amount,
            });
            setStatus("approve");
          } else {
            setStatus("error");
          }
        },
        (error) => {
          console.log(error);
        }
      )
    );
  };

  return (
    <div className={styles.main}>
      <TokenHandler />
      <div className={styles.container}>
        {/* banner */}
        <div className={styles.banner}>
          <div className={styles.profile}>
            <img src={profile} alt="" />
          </div>
          <div className={styles.address}>
            <div className={styles.name}>{memberInfo.name}</div>
            <div className={styles.number}>{memberInfo.phoneNo}</div>
          </div>
          <div
            className={styles.close}
            onClick={() => navigate("/point-add/phone-number")}
          >
            <CloseButton />
          </div>
        </div>

        {/* form */}
        <Formik
          initialValues={{
            brandId: "",
            memberId: "",
            amount: "",
            point: "",
          }}
          onSubmit={handleSearchClick}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
          }) => (
            <Form className={styles.form}>
              <div className={styles.details}>
                <div className={styles.detail}>
                  <label htmlFor="">{t("name")}</label>
                  <input type="text" value={memberInfo.name} readOnly />
                </div>

                <div className={styles.detail}>
                  <label htmlFor="">{t("phoneNumber")}</label>
                  <input type="text" value={memberInfo.phoneNo} readOnly />
                </div>

                <div className={styles.detail}>
                  <label htmlFor="">{t("totalPoints")}</label>
                  <input type="text" value={memberInfo.point} readOnly />
                </div>
              </div>

              <div className={styles.points}>
                <label htmlFor="pointType">Point Type</label>
                <select
                  name="pointType"
                  onChange={(e) => {
                    const { value } = e.target;
                    setPointType(value);
                    setFieldValue("amount", "");
                    setFieldValue("point", "");
                  }}
                  value={pointType}
                >
                  <option value="">Select Point Type</option>
                  <option value="addPoints">{t("addPoints")}</option>
                  <option value="usePoints">{t("usePoints")}</option>
                </select>
              </div>

              {pointType === "addPoints" && (
                <div className={styles.points}>
                  <label htmlFor="amount">Enter Amount</label>
                  <Field
                    type="text"
                    id="amount"
                    name="amount"
                    placeholder="Enter Add Point Amount"
                    required
                  />
                </div>
              )}

              {pointType === "usePoints" && (
                <div className={styles.points}>
                  <label htmlFor="point">Enter Amount</label>
                  <Field
                    type="text"
                    id="point"
                    name="point"
                    placeholder="Enter Use Point Amount"
                    required
                  />
                </div>
              )}

              <div className={styles.buttons}>
                <button
                  className={styles.cancel}
                  onClick={() => navigate("/point-add/phone-number")}
                >
                  {t("cancel")}
                </button>
                <button className={styles.confirm} type="submit">
                  {t("confirm")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      {/* success, failure modal box */}
      {status && (
        <div className={styles.modal}>
          <PointAddModal status={status} modalData={modalData} />
        </div>
      )}
    </div>
  );
};
export default PointAddForm;
