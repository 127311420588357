import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./languageSetting.module.scss";

const LanguageSetting = () => {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handleSelectChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
  };

  const handleSaveClick = () => {
    localStorage.setItem("language", selectedLanguage);
    handleLanguageChange(selectedLanguage);
  };

  return (
    <div className={styles.main}>
      {/* banner */}
      <div className={styles.banner}>
        <div className={styles.bannerText}>{t("language")}</div>
      </div>

      {/* form */}
      <form className={styles.form}>
        {/* select box */}
        <div className={styles.selectBox}>
          <label htmlFor="languages">{t("language")}</label>
          <select
            id="languages"
            name="languages"
            value={selectedLanguage}
            onChange={handleSelectChange}
          >
            <option value="mm">{t("myanmar")}</option>
            <option value="en">{t("english")}</option>
          </select>
        </div>

        {/* buttons */}
        <div className={styles.buttons}>
          <button type="button" className={styles.cancel}>
            {t("cancel")}
          </button>
          <button
            type="button"
            className={styles.save}
            onClick={handleSaveClick}
          >
            {t("save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default LanguageSetting;
