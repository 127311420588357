import { useEffect, useRef } from "react";
import jsQR from "jsqr";
import PropTypes from "prop-types";

const QRScanner = ({ onScanSuccess }) => {
  const videoElement = useRef(null);
  const intervalIdRef = useRef(null);
  const streamRef = useRef(null);

  const captureImage = () => {
    intervalIdRef.current = requestAnimationFrame(() => {
      try {
        if (!videoElement.current || !streamRef.current) {
          cancelAnimationFrame(intervalIdRef.current);
          return;
        }

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        canvas.width = videoElement.current.videoWidth;
        canvas.height = videoElement.current.videoHeight;
        context.drawImage(videoElement.current, 0, 0);

        const imageData = context.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );

        if (typeof jsQR === "function") {
          const code = jsQR(imageData.data, canvas.width, canvas.height);
          if (code?.data) {
            onScanSuccess(code.data);
          }
        }

        captureImage();
      } catch (error) {
        console.error(error);
      }
    });
  };

  useEffect(() => {
    if (streamRef.current) {
      const tracks = streamRef.current.getTracks();
      tracks.forEach((track) => track.stop());
    }

    navigator.mediaDevices
      .getUserMedia({
        video: { facingMode: "environment" },
      })
      .then((stream) => {
        if (videoElement.current) {
          videoElement.current.srcObject = stream;
        }

        streamRef.current = stream;
        videoElement.current.onloadedmetadata = () => {
          captureImage();
        };
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      console.log("Component will unmount");
      if (streamRef.current) {
        const tracks = streamRef.current.getTracks();
        tracks.forEach((track) => track.stop());
      }
      cancelAnimationFrame(intervalIdRef.current);
    };
  });

  return (
    <video
      ref={videoElement}
      autoPlay
      style={{
        border: "1px solid white",
        borderRadius: "10px",
        boxSizing: "content-box",
        width: "300px",
      }}
    >
      <track kind="captions" srcLang="en" label="English" />
    </video>
  );
};

QRScanner.propTypes = {
  onScanSuccess: PropTypes.func,
};

export default QRScanner;
