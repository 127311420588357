import styles from "./redeemGiftModal.module.scss";
import { SuccessIcon, FailedIcon } from "../../assets/svgCollection";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RedeemGiftModal = ({ status }) => {
  const { t } = useTranslation();

  let content;
  if (status === "approve") {
    // approve modal
    content = (
      <div className={styles.content}>
        <div className={styles.icon}>
          <SuccessIcon />
        </div>
        <div className={styles.textSupport}>
          <div className={styles.text}>{t("redeemGiftSuccess")}</div>
          <div className={styles.support}>{t("redeemGiftSuccessText")}</div>
        </div>
        <Link to="/" className={styles.button}>
          {t("goToHome")}
        </Link>
      </div>
    );
  } else if (status === "reject") {
    // reject modal
    content = (
      <div className={styles.content}>
        <div className={styles.icon}>
          <FailedIcon />
        </div>
        <div className={styles.textSupport}>
          <div className={styles.text}>{t("redeemGiftRejected")}</div>
          <div className={styles.support}>{t("redeemGiftRejectedText")}</div>
        </div>
        <Link to="/" className={styles.button}>
          {t("goToHome")}
        </Link>
      </div>
    );
  } else if (status === "error") {
    // error modal
    content = (
      <div className={styles.content}>
        <div className={styles.icon}>
          <FailedIcon />
        </div>
        <div className={styles.textSupport}>
          <div className={styles.text}>Gift Redeem Failed</div>
          <div className={styles.support}>
            Apologies, there was an error redeeming your gift. Please try again
            later.
          </div>
        </div>
        <Link to="/" className={styles.button}>
          {t("goToHome")}
        </Link>
      </div>
    );
  }

  return (
    <div className={styles.redeemGiftModal}>
      <div className={styles.main}>{content}</div>
    </div>
  );
};

export default RedeemGiftModal;
