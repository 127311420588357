import styles from "./pointAdd.module.scss";
import { Link } from "react-router-dom";
import { PhoneNumber, QRCode, Points } from "../../assets/svgCollection";
import { useTranslation } from "react-i18next";
import TokenHandler from "../../utils/TokenHandler";
import { useState } from "react";
import PointUsed from "../PointUsed/pointUsed";
import PointUsedModal from "../../components/PointUsedModal/pointUsedModal";

const PointAdd = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [qrData, setQrData] = useState({
    userId: "",
    amount: "",
  });

  return (
    <>
      <div className={styles.tabs}>
        <TokenHandler />
        {/* phone number tab */}
        <Link className={styles.tab} to="/point-add/phone-number">
          <div className={styles.img}>
            <PhoneNumber />
          </div>
          <div className={styles.text}>{t("phoneNumber")}</div>
        </Link>

        {/* qr scan tab */}
        <Link className={styles.tab} to="/point-add/qr-scan">
          <div className={styles.img}>
            <QRCode />
          </div>
          <div className={styles.text}>{t("qrScan")}</div>
        </Link>

        {/* point used tab */}
        <div className={styles.tab} onClick={() => setStatus(true)}>
          <div className={styles.img}>
            <Points />
          </div>
          <div className={styles.text}>{t("pointUsed")}</div>
        </div>
      </div>

      {status && (
        <div className={styles.modal}>
          <PointUsed
            setStatus={setStatus}
            setShowModal={setShowModal}
            setQrData={setQrData}
          />
        </div>
      )}

      {showModal && (
        <div className={styles.modal} onClick={() => setShowModal(false)}>
          <PointUsedModal
            qrData={JSON.stringify(qrData)}
          />
        </div>
      )}
    </>
  );
};
export default PointAdd;
